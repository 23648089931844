import logo from "./logo.svg";
import "./App.css";
import Sea from "./assets/01-sea.png";
import SeaOverlay from "./assets/02-riflessi-mare.png";
import FullIsland from "./assets/03-isola.png";

function App() {
  return (
    <div className="App">
      <img
        src={SeaOverlay}
        style={{
          width: "100vw",
          height: "150vh",
          objectFit: "cover",
          position: "absolute",
          bottom: 0,
          zIndex: 1,
        }}
      />
      <img
        src={FullIsland}
        style={{
          width: "90vw",
          height: "90vh",
          objectFit: "contain",
          position: "absolute",
          top: "5vh",
          left: "5vw",
          bottom: "5vh",
          right: "5vw",
          zIndex: 1,
        }}
      />
      <img
        src={Sea}
        style={{
          height: "100vh",
          width: "100vw",
          objectFit: "cover",
          zIndex: 0,
        }}
      />
    </div>
  );
}

export default App;
